import { apiUrl } from '../../config';

export default {
  login: `${apiUrl}/auth/login`,
  registration: `${apiUrl}/auth/register`,
  adminCompanyRegistration: `${apiUrl}/auth/admin/company-register`,
  resetPass: `${apiUrl}/auth/reset-pass`,
  generatePass: `${apiUrl}/auth/generate-pass`,
  changePass: `${apiUrl}/auth/change-pass`,
  resetPassAdmin: `${apiUrl}/auth/reset-pass-admin`,

  resolveConflictByEmail: `${apiUrl}/auth/resolve-conflict`,

  companiesList: `${apiUrl}/company/list`,
  lastCreatedCompanies: `${apiUrl}/company/last-created`,
  deletedCompanies: `${apiUrl}/company/deleted`,


  writeUs: `${apiUrl}/company/request-from-company`,
  companyItem: id => `${apiUrl}/company/${id}`,
  companyInitial: `${apiUrl}/company/admin/default-company`,

  updateCompany: id => `${apiUrl}/company/${id}`,
  // TODO: You need to make one url to load the logo
  updateCompanyLogo: id => `${apiUrl}/company/${id}/logo`,
  deleteCompanyLogo: id => `${apiUrl}/company/${id}/logo`,
  addCompanyVisit: id => `${apiUrl}/company/${id}/visit`,
  pictureCompany: id => `${apiUrl}/company/${id}/company-picture`,
  employeeAvatar: id => `${apiUrl}/company/${id}/employee-avatar`,
  /*For admin*/
  setCompanyStatus: id => `${apiUrl}/company/${id}/set-status`,
  updateVerifiedCompany: id => `${apiUrl}/company/${id}/verify`,

  companyReviews: companyId => `${apiUrl}/company/reviews/${companyId}`,
  createCompanyReview: `${apiUrl}/review`,
  createOwnerAnswer: `${apiUrl}/review/answer`,
  setRating: `${apiUrl}/rating`,

  activeUser: `${apiUrl}/user`,
  updateUser: `${apiUrl}/user`,
  userReview: `${apiUrl}/user/comments-left`,
  userChangeAvatar: `${apiUrl}/user/avatar`,
  userDeleteAvatar: `${apiUrl}/user/avatar`,
  contactSupport: `${apiUrl}/user/contact-support`,
  appStatistic: `${apiUrl}/statistic`,
  activeFilters: `${apiUrl}/statistic/active-filters`,
  activeGraphFilters: `${apiUrl}/company/list/graph`,
  locations: `${apiUrl}/statistic/locations`,
  companyStaticValues: `${apiUrl}/statistic/company-static-values`,
  contactCompany: `${apiUrl}/contact`,

  unverifiedCommentsList: `${apiUrl}/review/list`,
  unhandledCommentsCounts: `${apiUrl}/review/unhandled`,
  postCommentByAdmin: commentId => `${apiUrl}/review/${commentId}/verify`,
  commentByAdmin: commentId => `${apiUrl}/review/${commentId}`, // use it with DELETE and PATCH
  postCommentAnswerByAdmin: commentId => `${apiUrl}/review/${commentId}/answer/verify`,
  commentAnswerByAdmin: commentId => `${apiUrl}/review/${commentId}/answer`, // use it with DELETE and PATCH
  postRatingByAdmin: ratingId => `${apiUrl}/rating/${ratingId}/verify`,
  deleteRatingByAdmin: ratingId => `${apiUrl}/rating/${ratingId}`,
  
  updateEntitiesById:`${apiUrl}/infra/updateEntitiesById`
}
