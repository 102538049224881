/* eslint-disable max-len */
export default {
  general: {
    back: 'Back',
    searchBtn: 'Search',
    serverErrorsByCodes: {
      3: 'Something wrong with server, please try later',
      54: 'Email is not valid',
      57: 'The company with the given email does not exist',
      58: 'Wrong password',
      59: 'Admin with such mail does not exist',
      61: 'Company with given email already exist',
      62: 'Phone number os invalid. Should contains only numbers',
      64: 'Company with such name exist in the system',
      65: 'Old password is incorrect',
      /*Custom errors on client*/
      1000: 'User is not admin. '
    },
    paginator: {
      noMoreItemsMessage: 'No more items to show'
    }
  },
  header: {
    companyName: 'TreuhandSuche',
    inputPlaceholder: 'Search',
    menu: {
      blog: 'Blog',
      loginForCompanies: 'Login',
      profile: 'Profile',
      userName: 'User',
      myProfile: 'My Profile',
      myCompany: 'My Company',
      logout: 'Logout',
      forCompanies: 'For companies',
      forum: 'Forum'
    }
  },
  drawerMenu: {
    contacts: 'Contacts',
    blog: 'Blog',
    forCompanies: 'For companies',
    agb: 'Terms of Service',
    dataProtection: 'Privacy Policy',
    registration: 'Registration',
},
  footer: {
    footerMenuPart: {
      forum: 'Forum',
      contacts: 'Contacts',
      blog: 'Blog',
      faq: 'FAQ',
      forCompanies: 'For companies',
      fb: 'Facebook',
      linkedIn: 'LinkedIn'
    },
    footerLowPart: {
      copyRight: 'treuhand-suche.ch © {{year}}',
      rights: 'All rights reserved',
      terms: {
        agb: 'Terms of Service',
        agbMobile: 'Terms of Service',
        dataProtection: 'Privacy Policy'
      }
    }
  },
  homeFilter: {
    labels: {
      communicationPreferences: 'Focus of communication',
      servicesBasis: 'Accounting basics',
      specialOffer: 'Special offer*',
    },
    placeholders: {
      zipCode: 'Zip',
      city: 'City',
      canton: 'Canton',
      software: 'Accounting software',
      industries: 'Industries',
      services: 'Services'
    },
    errors: {
      noCityFound: 'Not a valid location',
      noZipFound: 'Not a valid zip'
    },
    comment: 'With this selection you will find an accountant with a special offer, e.g. discounts or package solutions.'
  },
  modals: {
    auth: {
      registration: {
        header: 'Registration',
        userTabName: 'For users',
        companyTabName: 'For company',
        bottomCapture: {
          text: 'By clicking on the button you agree with our ',
          privacyBtn: 'Privacy Policy',
          and: ' and ',
          termsBtn: 'Terms of Use.'
        },
        backToLoginMsg: 'Have you already registered?',
        singInBtn: 'Sign in',
        notificationBar: {
          success: 'New company created',
          error: 'Registration failed'
        }
      },
      login: {
        header: 'Welcome back!',
        forgotPass: 'Forgot your password?',
        noSignedMsg: 'Not signed up yet?',
        singUpBtn: 'Sign up'
      },
      resetPass: {
        header: 'Reset your password',
        capture: 'Enter your email address below. We\'ll look for your account and send you a password reset email'
      },
      sendMail: {
        header: 'Apparently your company is already registered on the platform',
        capture: 'Please check this profile. Is it your company?',
        desc: 'If not, please enter your email and company name. We will contact you as soon as possible.',
      },
    },
    changeLogo: {
      header: 'Change Image',
      selectImage: 'Select image',
      deleteImage: 'Clear image',
      close: 'Close',
    },
    filters: {
      header: 'Filters',
      revertBtn: 'Revert',
      applyBtn: 'Apply',
      languages: {
        Deutsch: 'German',
        Englisch: 'English',
        Französisch: 'French',
        Italienisch: 'Italian ',
        Russisch: 'Russian',
        Holländisch: 'Dutch',
        Spanisch: 'Spanish',
        Portugiesisch: 'Portuguese',
        Arabisch: 'Arabian',
      }
    },
    changePass: {
      header: 'Replace password',
      form: {
        oldPass: {
          label: "Old password",
          placeHolder: "Enter old password",
          errors: {
            required: 'Password is required',
          }
        },
        newPass: {
          label: "Enter new password",
          placeHolder: "Enter new password",
          errors: {
            required: 'Password is required',
            equal: 'New password must be different',
          }
        },
        repeatNewPass: {
          label: "Repeat new password",
          placeHolder: "Confirm new password",
          errors: {
            required: 'Password is required',
            noEqual: 'Different passwords',
          }
        }

      },
      submitBtn: "Save",
      cancelBtn: "Cancel",
      changeBtn: 'Change',
    },
    resetPass: {
      header: 'Reset password',

      form: {
        newPass: {
          label: {
            start: 'The new password for the profile "',
            end: '" is:'
          },
          errors: {
            required: 'Password is required',
            equal: 'New password must be different',
          }
        },
        sendMail: {
          start: "This password was sent to",
          end: ""
        }
      },
      submitBtn: "Send",
      cancelBtn: "Cancel",

      notificationBar: {
        success: 'Password was reset successfully',
        error: 'Failed to reset password'
      }
    },
    writeUs: {
      header: "Write us",
      form: {
        companyName: {
          label: "Company name",
          placeHolder: "Enter company name",
          required: 'Company name is required'
        },
        firstName: {
          label: "Your Name",
          placeHolder: "Enter name",
          required: 'Your Name is required'
        },
        email: {
          label: "Email",
          placeHolder: "Enter email",
          required: "Email is required",
          isNotValid: "Email is not valid"
        },
        comment: {
          label: "Your Message",
          placeHolder: "Enter text",
          required: "Message is required",
        },
        submitBtn: "Send"
      },
      notificationBar: {
        success: "Comment sent",
        error: "An error occurred while submitting. Submission failed"
      },
    },
    reviewAlert: {
      thanksText: 'Thank you!',
      notification: 'Your feedback will be processed by our team and posted.'
    },
    successModal: {
      thanksText: 'Thank your for your inquiry!',
      notification: 'Your message was sent the company {{ company }}.',
      error: 'Message not sent'
    }
  },
  forms: {
    common: {
      emailForm: {
        label: 'Email',
        placeholder: 'Enter your email',
        errors: {
          required: 'Email is required',
          isNotValid: 'Email is not valid',
        },
        submitBtn: 'Submit',
      },
      companyName: {
        placeholder: 'Enter your company name',
        errors: {
          required: 'Company name is required',
        },
      },
      name: {
        label: 'Name',
        placeholder: 'Enter your name',
        errors: {
          required: 'Name is required'
        },
      }
    },
    login: {
      labels: {
        email: 'Email',
        pass: 'Password'
      },
      placeholders: {
        email: 'Email address',
        pass: 'Enter your password'
      },
      errors: {
        email: {
          required: 'Email is required',
          isNotValid: 'Email is not valid'
        },
        pass: {
          required: 'Password is required',
        }
      },
      submitBtn: 'Login',
    },
  },
  homepage: {
    mainCapture: 'Find the right',
    mainCapturePartTwo: 'accountant',
    mainCapturePartThree: 'or',
    mainCapturePartFour: 'tax advisor',
    secondaryCapture: 'Overview of the Swiss consulting industry',
    advancedFilterBtn: 'Advanced Search',
    infoBlock: {
      freeProfiles: {
        header: 'Free profile registration',
        capture: 'Join us and introduce yourself to new potential customers!'
      },
      companiesCount: {
        header: 'Over 2,600 accountants',
        capture: 'The database holds numerous contacts of accountants from German-speaking Switzerland, and updates every day.'
      },
      fields: {
        header: 'Detailed filter options',
        capture: 'Find an accountant  with a suitable specialization or filter according to additional criteria, such as hourly rates.'
      },
      locations: {
        header: 'Accountants nearby',
        capture: 'Find an accountant or tax advisor in your area. All German-speaking cantons are included.'
      },
    },
    companiesCarousel: {
      title: 'New',
      subtitle: 'Accountants profiles',
      loadMoreBtn: 'All companies',
      goToCompanyBtn: 'To company details'
    },
    blogCarousel: {
      title: 'Blog',
      loadMoreBtn: 'All posts'
    },
    headerBeforeRegistration: 'Create your free account today',
    subHeaderBeforeRegistration: 'What are the benefits to join Treuhandsuche?',
    registrationFormTitle: 'Join us',
    registrationFormSubTitle: 'And get everything for free',
    registrationForm: {
      labels: {
        name: 'Name',
        phone: 'Phone number',
        email: 'Email',
        pass: 'Password',
        confirmPassword: "Repeat password"
      },
      placeholders: {
        name: 'Company name',
        phone: 'Phone number',
        email: 'Email address',
        pass: 'Enter your password',
        confirmPassword: 'Repeat your password',
      },
      errors: {
        name: {
          required: 'Company name is required',
        },
        phone: {
          formatIsWrong: 'Phone is not in right format',
          required: 'Phone is required',
        },
        email: {
          required: 'Email is required',
          isNotValid: 'Email is not valid'
        },
        pass: {
          required: 'Password is required',
        },
        confirmPass:{
          required: 'Confirm password is required',
          confirmPassword: 'Passwords are not the same'
        },

      },
      submitBtn: 'Next',
      successMessage: 'Company was successfully created.'
    },
    infoTextInRegistrationSection: {
      first: {
        header: 'Advertise your business',
        capture: 'Communicate your services, and offer them to a broad audience.',
      },
      second: {
        header: 'Strengthen your brand',
        capture: 'Introduce your company to a specific target group: as a local brand, as an industry expert or as a price leader.',
      },
      third: {
        header: 'Engage new customers',
        capture: 'Treuhandsuche generates direct requests from prospective customers. Acquire new customers with an attractive profile.',
      }
    },
    subscribeSection: {
      capture: 'Get informed about events and news',
      email: {
        placeholder: 'Your email',
        required: 'Email is required',
        invalid: 'Email is not valid'
      },
      emailLabel: 'Your email address',
      subscribeBtn: 'Subscribe',

    }
  },
  companies: {
    topCapture: 'Search result',
    amountOfItems: 'accountants and tax advisors in ',
    amountOfItemsMobile: 'Accountants',
    country: 'Switzerland',
    selectPerPageCapture: 'Display: ',
    profile: 'Profiles',
    sortCapture: 'Sort: ',
    allOption: 'All',
    noCompaniesNoFoundByQuery: {
      firstPart: 'Your request could not be fulfilled...',
      secondPart: 'But we found these trustee nearby:'
    },
    loadMoreCompaniesMobile: 'More company profile',
    drawerFilterTitle: 'Filter',
    drawerFilterSubmitBtn: 'Apply filter',
    filter: {
      capture: 'Filter',
      clearFilterBtn: 'Clear',
      clearFilterBtnMobile: 'Reset the filter',
      cantonTitle: 'Canton',
      cityTitle: 'City',
      zipTitle: 'ZIP group',
      rateTitle: 'Hourly rate',
      softwareTitle: 'Accounting software',
      communicationPreferencesTitle: 'Focus of communication',
      servicesBasisTitle: 'Accounting basics',
      servicesTitle: 'Specialization',
      industriesTitle: 'Industry',
      specialOfferTitle: 'Special offer',
      languagesTitle: 'Languages',
      memberTitle: 'Member',
      taxRepresentationTitle: 'Tax representation',

      rate: 'Hourly rate in CHF',
      services: 'Services',
      languages: 'Languages',
      member: 'Membership',
      software: 'Software',
      pictures: 'Pictures',

      rates: {
        secretary: 'Secretariat',
        expert: 'Experts',
        auditor: 'Auditor',
        accountant: 'Accounting'
      },
      histogramTitle: 'Average hourly rate:',
      histogramText: '{{ value }} per hour',
      rangeInputMinLabel: 'Minimum hourly rate',
      rangeInputMaxLabel: 'Maximum hourly rate',
      histogramResetBtn: 'Clear',
      histogramSaveBtn: 'Save',

      searchPlaceholders: {
        kanton: 'Search canton',
        city: 'Search city',
        zip: 'ZIP search',
        software: 'Search software',
        service: 'Search service',
        industries: 'Search industry',
        language: 'Search language',
      },
      searchErrorMessages: {
        kantonNotFound: 'Not valid canton',
        cityNotFound: 'Not valid location',
        zipNotFound: 'Not valid ZIP',
        softwareNotFound: 'Not valid software',
        serviceNotFound: 'Not valid service',
        industriesNotFound: 'Not valid industry',
        languageNotFound: 'Not valid language',
      }
    },
    sorts: {
      mostPopular: 'Relevance',
      views: 'Popularity',
      name: 'In ABC order'
    },
    companyCard: {
      servicesLabel: 'Services',
      rateLabel: 'Hourly rate in CHF',
      softwareLabel: 'Software',
      viewsLabel: 'Profile Clicks',
      viewProfileBtn: 'View Profile',
      basicService: 'Accounting basics',
      rates: {
        accountant: 'Accounting',
        auditor: 'Auditor',
        expert: 'Experts',
        secretary: 'Secretariat'
      },
      communicationPreferences: {
        online: 'Online service',
        betreuungVorOrt: 'On-site service'
      },
      infoTitles: {
        services:'Services:',
        languages:'Languages:',
        memberships:'Memberships:',
        views:'Views',
        foundingYear: 'Founding Year:',
        vatNumber: 'VAT Number:',
        employees: 'Number of Employees:',
        description: 'Description:',
        hourlyRates: 'Hourly Rates in CHF:',
        mainIndustries: 'Main Industries:',
        team: 'Team:',
        companyPhotos: 'Company Photos:',
        swissFranc: 'CHF',
        secretary:'Sekretariat',
        auditor:'Mandatsleiter',
        accountant:'Buchhaltung',
        expert: 'Experten',
      }
    }
  },
  companyItem: {
    capture: 'My company',
    captureAccountant: 'Your accountant',
    goBackBtn: 'Go back',
    companyProfile: 'Company profile',
    goBackProfile: 'Back to profile',
    companyLogoAltValue: 'Company logo',
    communicationPreferences: {
      online: 'Online service',
      betreuungVorOrt: 'On-site service'
    },
    editBtn: 'Edit',
    verified: 'Verified',
    notVerified: 'Not verified',
    views: 'Views',
    employees: 'Employees',
    yearOfFoundation: 'Founding year',
    mehrwertsteuerNum: 'MwSt-Nr',
    ratingCount: "{{ count }} review",
    ratingCount_plural: "{{ count }} reviews",
    ratingComponent: {
      tooltip: {
        main: 'Average rating on',
        averageTreuh: 'treuhand-suche.ch',
        averageGoogle: 'Google My Business',
        averagePortal: 'Average ratings from 2 Portalen ',
        averageTreuhandvergleich: 'treuhandvergleich.ch*',
        reviews: 'Reviews',
        desc: '*Ratings were converted into ',
        descStarSystem: 'a five-star system'
      },
      noRating: 'Not Specified'
    },
    infoItemsCategoriesName: {
      desc: 'Description',
      rate: 'Hourly rate in CHF',
      services: 'Services',
      industries: 'Industry',
      languages: 'Languages',
      member: 'Membership',
      software: 'Software',
      pictures: 'Company photos',
      employees: {
        title: 'Team',
        managerEmployees: 'Management',
        otherEmployees: 'Other employees'
      },
      specialOffer: 'Special offer',
      taxRepresentation: 'Tax representation'
    },
    rates: {
      secretariat: 'Secretariat',
      auditor: 'Auditor',
      accounting: 'Accounting',
      experts: 'Experts'
    },
    infoBlock: {
      header: 'Is this your company?',
      subHeader: 'Write to us, and create a free account with our moderators to use the following options:',
      createAccount: 'Contact us',
      listItem1: 'Answer the comments about your company',
      listItem2: 'Correct the information about your company',
      listItem3: 'Advertise your services',
      listItem4: 'Introduce the company´s management and employees',
      listItem5: 'Set backlink to your website',
    },
    contactBlock: {
      header: 'Contact form',
      required: '*Required',
      form: {
        firstName: {
          label: "First Name",
          placeholder: "Enter first name",
          isRequired: 'First name is required'
        },
        lastName: {
          label: "Last Name",
          placeholder: "Enter last name",
          isRequired: 'Last name is required'
        },
        companyName: {
          label: "Company name",
          placeholder: "Enter the name of your company"
        },
        employeeCount: {
          label: "Number of employees",
          placeholder: "Enter the number of employees",
          isNotValid: {
            type: "It must be a number",
            notLessOne: 'It should not be less 1'
          }
        },
        phoneNumber: {
          label: "Phone number"
        },
        email: {
          label: "Email address",
          placeholder: "Enter your email",
          isRequired: "Email is required",
          isNotValid: "The email address is in the wrong format"
        },
        message: {
          label: "Your Message",
          placeholder: "Enter the text of your inquiry",
          isRequired: "Message is required"
        },
        submitBtn: "Send"
      }
    },
    reviewsBlock: {
      headerCapture: 'Reviews',
      createReviewBtn: 'Create review',
      answerBtn: 'Answer',
      reportBtn: 'Report',
      ownerAnswerLabel: 'Official answer by owner',
      commentsTitle: 'Comments',
    }
  },
  companyEdit: {
    capture: 'Edit company profile',
    saveBtn: 'Save',
    addItemBtn: 'Add one',
    settingsTypes: {
      main: 'Main info',
      settings: 'Settings',
      social: 'Website and Social Media',
      desc: 'Description',
      rate: 'Hourly rate in CHF',
      team: 'Team',
      services: 'Services',
      industry: 'Industry',
      languages: 'Languages',
      member: 'Membership',
      software: 'Software',
      pictures: 'Pictures',
      specialOffer: 'Special offer',
      taxRepresentation: 'Tax representation'
    },
    errorMessages: {
      name: 'Name is required',
      email: 'Email has wrong format',
      email_required: 'Email field is required',
      website: 'Enter your website using format www.muster.ch',
      phone: {
        type: 'Phone number should contain only numbers'
      },
      rate: {
        mustBeNumber: 'Value should be number',
        maxLimit: 'Value should be less than 500'
      },
      employeeCount: {
        type: 'Count of employee should be number',
        notLessOne: 'Count of employee should not be less 1'
      },
      fileSize: 'File is too large (max 5 MB)',
      socialMedia: {
        skype: 'Skype Name must be between 6 and 32 characters long',
        whatsApp: 'Please enter valid phone number',
        default: 'Please enter a valid link'
      },
    },
    rateWarningMsg: {
      title: 'Important!',
      desc: 'Information about the hourly rates contributes disproportionately to the company ranking. The higher your ranking is, the higher your company is in search results. In addition, customers can use hourly rates as search criteria. If it is absent in your company profile, the company won´t be included in the search results.'
    },
    selectPlaceholders: {
      canton: 'Select canton',
      industries: 'Select industry',
      languages: 'Select language',
      member: 'Select member',
      software: 'Select software',
      services: {
        specialisation: 'Select specialisation',
      },
      socialMedia: 'Select social'
    },
    labels: {
      name: 'Company name',
      canton: 'Canton',
      city: 'City',
      street: 'Street and house number',
      streetPlaceholder: 'Street, Nr.',
      zip: 'ZIP',
      email: 'Email',
      phone: 'Phone number',
      employeeCount: 'Number of employees',
      website: 'Website',
      desc: 'About your company',
      communicationPreferences: 'Communication preferences',
      workingHours: 'Working hours',
      workingHoursSelection: 'Your selection',
      industries: 'Industry',
      languages: 'Language',
      member: 'Member',
      software: 'Software',
      otherSoftware: 'Other software',
      otherLanguages: 'Other languages',
      otherMembers: 'Other members',
      rate: {
        secretary: 'Secretariat',
        accountant: 'Accountant',
        auditor: 'Auditor',
        expert: 'Expert',
      },
      services: {
        basics: 'Accounting basics',
        specialisation: 'Specialization'
      },
      yearOfFoundation: 'Founding year',
      mehrwertsteuerNum: 'VAT-Nr',
      socialMedia: 'Social Media',
      specialOffer: 'About your special offer',
      taxRepresentationBlock: 'Tax representation',
      taxRepresentationField: 'yes'
    },
    notificationBar: {
      success: 'Profile saved',
      error: 'When saving an error has occurred. Changes failed.',
      successUploadPicture: 'Picture saved',
    },
    progressBar: {
      current     : 'Current',
      completeText: 'Complete your profile'
    },
    communicationPreferences: {
      online:     'Online service',
      betreuungVorOrt: 'On-site service'
    },
    workingHours: {
      days: {
        full: {
          monday: 'Monday',
          tuesday: 'Tuesday',
          wednesday: 'Wednesday',
          thursday: 'Thursday',
          friday: 'Friday',
          saturday: 'Saturday',
          sunday:  'Sunday'
        },
        short: {
          monday: 'Mon',
          tuesday: 'Tue',
          wednesday: 'Wed',
          thursday: 'Thu',
          friday: 'Fri',
          saturday: 'Sat',
          sunday:  'Sun'
        }
      },
      add: 'Add opening hours',
      close: 'Delete opening times',
      form: 'from',
      to: 'to',
      byAppointment: 'by appointment',
      timeDimension: 'Hour'
    },
    pictures: {
      delete: 'Clear',
      addPhotos: 'Add photos',
    },
    socialMedia: {
      names: {
        facebook: 'Facebook',
        linkedIn: 'LinkedIn',
        skype: 'Skype',
        whatsApp: 'WhatsApp',
        twitter: 'Twitter',
        googleMyBusiness: 'Google My Business',
        youtube: 'Youtube'
      },
      placeholders: {
        website: 'www.muster.ch',
        facebook: 'https://www.facebook.com/...',
        linkedIn: 'https://www.linkedin.com/company/...',
        skype: 'Skype Name',
        whatsApp: '+41000000000',
        twitter: 'https://www.twitter.com/...',
        googleMyBusiness: 'https://business.google.com/...',
        youtube: 'https://www.youtube.com/channel/...'
      }
    },
    teamSection: {
      managersTitle: 'Management',
      othersStaffTitle: 'Other employees',
      addEmployeeBtn: 'Add person',
      sexTypes: {
        male: 'Men',
        female: 'Women'
      },
      fieldLabels: {
        sexType: 'Gender',
        firstName: 'First name',
        lastName: 'Last name',
        jobTitle: 'Job title',
        email: 'Email',
        education: 'Education'
      },
      fieldPlaceholders: {
        jobTitle: 'e.g.: Co-Founder',
        education: 'e. g.: Master of Science in Finance'
      },
      errors: {
        jobTitleRequired: 'Job title is required'
      }
    },
    fieldComment: {
      settingsLogo: [
        'Please use the PNG-File for your profile logo.',
        'If your company logo consists of both icon and text, it is better to use only icon.'
      ],
      settingsEmail: [
        'This email is your company profile login.',
        'This email will also be used to send the visitors’ questions from the contact form in your profile.'
      ],
      main: [
        'Choose which type of cooperation with customers, you prefer: «online communication» or «face-to-face communication». You have to choose one preferred type of communication (no double selection).',
        'The customers can then filter according to their preference according to this criterion.'
      ],
      desc: [
        'Please describe your company. Here you can describe your services and your company in more details. This information won\'t be used as a filter criteria and is only of a descriptive character.'
      ],
      social: [
        'Please enter your company website and social networks or messengers contacts. With this information your potential customers have an opportunity to get know you better. Besides, it offers more communication options.'
      ],
      servicesBasicsList: [
        'Accounting', 
        'Financial statements',
        'Payroll accounting', 
        'Value added tax', 
        'Tax return for companies', 
        'Tax return for private persons'
      ],
      servicesBasics: [
        'These skills are part of the basic knowledge of any accounting consultant. If you only offer specialized services (e.g. legal or tax advice), leave this option blank.'
      ],
      servicesSpecialization: [
        'Select the areas in which you have special expertise. You can choose maximum two areas.',
        'Customers who look for advice in these areas can search directly your special expertise.',
        'You are also welcome to specify other competencies in the general description. However, this information is not considered in the search.'
      ],
      industries: [
        'Choose the industries in which you have specific industry experience. You can select maximum five industries.',
        'Customers who search for a consultant with a specific industry experience can then filter search results specifically for this industry.'
      ],
      languages: [
        'Please indicate the languages that you can serve customers. If you cannot find a suitable language in the list, you can enter it as a free text.',
        'Customers who search for a trustee with a specific language can then filter according to this criterion. However, the system performs search only for languages from the list.'
      ],
      software: [
        'Please choose which accounting programs do you work with. If there is no suitable software in the list, you can enter it as a free text.',
        'Customers who search for a consultant who works with a specific accounting program can then filter particularly according to this criterion. However, the system performs search only for accounting programs from the list.'
      ],
      pictures: [
        'Here you can upload photos to present your company. For example: pictures of your office, the surroundings or the team events. Or you can use other images that describe your company.'
      ],
      member: [
        'Please indicate your memberships. If there are no suitable memberships in the list, you can enter them as a free text.',
        'Customers who search for a consultant with a specific membership can then filter particularly according to this criterion. However, the system performs search only for memberships from the list.'
      ],
      specialOffer: [
        'Describe your special offers. It can be a special offer for start-up or attractive fixed price for the tax return.'
      ],
      taxRepresentation: [
        'Please indicate whether you offer tax representation for your customers.'
      ]
    }
  },
  reviewCreate: {
    header: 'New review',
    submitBtn: 'Post',
    userName: 'Guest',
    ratingCapture: 'Rate the company',
    headersCaptures: {
      userAcc: 'User account',
      info: 'Your information',
      ownerAnswer: 'Your answer'
    },
    userDataForm: {
      labels: {
        name: 'Name',
        email: 'Your Email Address',
        text: 'Your message'
      }
    },
    notificationBar: {
      success: 'Comment sent.',
      successRating: 'Rating sent',
      error: 'An error occurred while submitting. Submission failed.'
    },
    commentPostedAfter: 'Your comment will be posted after the moderation.',
    textValidationRequired: 'Text for review is required',
    nameValidationRequired: 'User name for review is required',
    emailValidationRequired: 'Email is required',
    emailIsNotValid: 'Email is not valid',
  },
  profile: {
    header: 'Profile',
    profileTabs: {
      comments: 'Comments',
      settings: 'Settings',
    },
    settings: {
      selectImage: 'Select image',
    },
    labels: {
      name: 'Name',
      email: 'Email Address',
      password: 'Password'
    },
    button: {
      save: 'Save',
      editCompanyProfile: 'To company profile',
      changePassword: 'Change Password',
      resetPassword: 'Reset Password',
    },
    notificationBar: {
      successChangePass: 'Password changed',
      successResetPass: 'Password was reset',
      successUpdateUser: 'Profile saved',
      errorUpdateUser: 'When saving an error has occurred. Changes failed.',
      errorChangePass: 'The password has not been changed'
    }
  },
  logoText: {
    changeLogo: 'Change picture'
  },
  contactsPage: {
    title: 'About us',
    contactTitle1: 'Do You Have Questions?',
    contactTitle2: 'Our team likes to help you!',
    contactDesc: 'If you need help with your account, have any questions about using the platform or have technical problems, please do not hesitate to contact us.',
    email: 'Email:',
    socialMedia: 'Social media:',
    socialMediaFacebook: 'Follow us on Facebook',
    titleForm: 'Write to us',
    submitBtn: 'Send',
    form: {
      labels: {
        name: 'Name',
        email: 'Email',
        text: 'Your message',
      },
      placeholders: {
        name: 'Enter name',
        email: 'Enter email address',
        text: 'Text message',
      },
      errors: {
        emailRequired: 'Email is required',
        nameRequired: 'Name is required',
        textRequired: 'Message is required',
        isNotValid: 'Email is not valid'
      }
    },
    notificationBar: {
      success: 'Message sent.',
      error: 'Message not sent.',
    }
  },
  forCompanies: {
    title: 'Improve your business now!',
    titleDescription: 'A mix of the most complete accountant listings in Switzerland, together with an intelligent algorithm, are key success factors in optimally matching our customers’ needs with the most appropriate consultant, and offering them better comparability. For us, the only good customer is a satisfied customer.',
    titleBtn: 'Let\'s go!',
    contentTitleOne: 'Advertising with no misuse',
    contentDescriptionOne: 'Increase your online presence and sharpen business profile with the Treuhandsuche platform. You have the unique opportunity to advance your profile recognition and acquaint others with your services. Targeted advertising – with a misuse rate reduced to minimum – allows to find (or to be found by) companies that need exactly the services you offer.',
    contentTitleTwo: 'Customer acquisition',
    contentDescriptionTwo: 'Acquire more new customers, and increase your sales. With treuhand-suche.ch, your company also has the opportunity to establish a true customer trust, and create a strong reputation.',
    singUpTitle: 'Sign up with us!',
    singUpSubTitle: 'And use all options for free!',
    singUpDescription: 'By linking your Fiduciary Search profile to your website, you can increase the web traffic ' +
      'of your website directly and indirectly through increased organic search queries in Google.',
    submitButton: 'Register',
  },
  pageNotFound: {
    title: 'The page you are looking for cannot be found',
    button: 'Back to start page'
  },
  companyLanguages: {
    'Alle': 'All',
    'Deutsch': 'German',
    'Englisch': 'English',
    'Französisch': 'French',
    'Italienisch': 'Italian',
    'Russisch': 'Russian',
    'Holländisch': 'Dutch',
    'Spanisch': 'Spanish',
    'Portugiesisch': 'Portuguese',
    'Arabisch': 'Arabian',
    'Rätoromanisch': 'Rhaeto-Romanic',
    'Bulgarisch': 'Bulgarian',
    'Finnisch': 'Finnish',
    'Griechisch': 'Greek',
    'Albanisch': 'Albanian',
    'Rumänisch': 'Romanian ',
    'Slowakisch': 'Slovak',
    'Türkisch': 'Turkish',
    'Hebräisch': 'Hebrew',
    'Mazedonisch': 'Macedonian',
    'Serbokroatisch': 'Serbo-Croatian',
    'Chinesisch': 'Chinese',
    'Polnisch': 'Polish',
    'Ungarisch': 'Hungarian',
    'Norwegisch': 'Norwegian',
    'Kroatisch': 'Croatian',
    'Serbisch': 'Serbian',
    'Dänisch': 'Danish',
    'Schwedisch': 'Swedish',
    'Tschechisch': 'Czech',
    'Tamil': 'Tamil',
    'Bosnisch': 'Bosnian'
  },
  companyBasicsServices: {
    label: 'Accounting basics'
  },
  companySoftware: {
    'Alle': 'All',
    'Bexio': 'Bexio',
    'Banana': 'Banana',
    'Office Maker': 'Office Maker',
    'LoopsFinanz': 'LoopsFinanz',
    'Klara': 'Klara',
    'Abacus': 'Abacus',
    'Sage': 'Sage',
    'Comatic': 'Comatic',
    'CashCtrl': 'CashCtrl'
  },
  companyServices: {
    'Alle': 'All',
    'MWST-Fragen': 'Complex VAT problems',
    'Lohnbuchhaltung': 'Complex payroll accounting problems',
    'Personalwesen/Arbeitsrecht': 'HR-Management/Labour law',
    'Vorsorgeberatung': 'Pension advice',
    'Wirtschaftsprüfung': 'Financial audit',
    'Revision': 'Revision',
    'Juristische Beratung': 'Legal consultancy',
    'Unternehmensberatung': 'Business consultancy',
    'Steuerberatung Schweiz': 'Swiss tax consultancy',
    'Steuerberatung International': 'International tax consultancy',
    'Erbschaftsberatung': 'Inheritance consultancy',
    'Nachfolgeplanung': 'Succession planning',
    'Immobilienverwaltung': 'Real estate management',
    'Domizil-Services': 'Domicile Services',
    'Firmengründung': 'Company foundation',
    'Vermögensverwaltung': 'Asset management',
    'Finanzplanung': 'Financial planning',
    'Businesspläne': 'Business plans'
  },
  companyIndustries: {
    'Alle': 'All',
    'Gastgewerbe': 'Hotels and restaurant industry',
    'Finanzen': 'Financial services and insurance',
    'Information': 'Information and communication',
    'Landwirtschaft': 'Agriculture, forestry and fishery',
    'Kunst': 'Art, entertainment and recreation',
    'Grundbesitz': 'Real estate',
    'Verkehr': 'Logistics and storage',
    'Autogewerbe': 'Auto trade',
    'Versorgung': 'Supply and disposal (energy, water, etc.)',
    'Sonstige': 'Other services'
  },
  communicationPreferences: {
    online: 'Online service',
    betreuungVorOrt: 'On-site service'
  },
  findCompany: {
    companyTitleText: 'Looking for an accountant?',
    companySecondaryText: 'Do you need a consultation for a company foundation? Or do you need some help with VAT in Basel? On treuhand-suche.ch we have collected accountants and tax advisors from Zurich, Winterthur, Basel, Lucerne, Bern, Solothurn and other German-speaking cantons. \n \n You will find such services as financial accounting, accounts receivable and accounts payable, payroll accounting, human resources, annual statements, tax returns and other services. \n \n Comprehensive profiles of companies give you all the necessary information to find a suitable accountant or ask him a question directly on treuhand-suche.ch. Our platform is ideal for small and middle Swiss companies, self-employed and private persons - with us you will find the best option for your budget.'
  },

  companyBlockNoTimeToSearch: {
    title: 'No time to search for an accountant?',
    descSecondary: 'We appreciate your time. Just fill out the form and we will match your requirements with the most suitable accountants or tax advisors from our database.',
    descThird: 'We don’t sell any leads, but provide your request to the most suitable experts for free.',
    button: 'Request an offer',
  },

  companyBlockConfusingTrustMarket: {
    title: 'Opaque market',
    descSecondary: 'The search for a new accountant can be time-consuming. Sometimes the market is opaque and confusing in terms of proficiency and price. While searching the Internet, you have to answer similar questions several times to receive some offers. Besides that, those offers have to be thoroughly analyzed for suitability.',
    descThird: 'For this reason, specialized offer portals save a certain amount of time. You fill out the inquiry once and receive several offers. However, the key problem with those portals is that your lead is commonly sold to some random providers. Often, the best chance to make you an offer is taken by the highest bidder or the fastest supplier. Wouldn\'t it be better to get it from the most suitable one instead? This is exactly what we want to improve.',
  },

  companyBlockOptimalMatching: {
    title: 'Optimal match is our top priority',
    descSecondary: 'On our platform, we have collected for you detailed profiles of Swiss accountants. You will find here all the most important information, including prices and hourly rates. Are you looking for an online accountant? - Define your own selection criteria!',
    descThird: 'You only have to enter your requirements once in our form and we will take over the matching process with the most suitable accountants for you! Alternatively, you can simply use our smart search algorithm and individually look for an accountant in our large database.',
  },

  companyBlockMap: {
    title: 'Treuhand-suche.ch is a Swiss online platform that matches accountants and tax advisors with their clients.',
    descSecondary: 'Our database contains over 2,600 accountant profiles from all German-speaking cantons: Zurich, Aargau, Appenzell Ausserrhoden, Appenzell Innerrhoden, Basel-Landschaft, Basel-Stadt, Bern, Freiburg, Glarus, Graubünden, Lucerne, Nidwalden, Obwalden, Schaffhausen, Schwyz, Solothurn, St. Gallen, Thurgau, Uri, Wallis and Zug. On our platform, you will find advisors for accounting or tax services: financial accounting, payroll accounting, taxes etc.',
  },

  adminPanel: {
    header: {
      addNewCompany: 'ADD NEW COMPANY',
      admins: 'Existing Administrators',
      myProfile: 'My Profile',
      logout: 'Logout'
    },
    sidebar: {
      home: 'Home',
      company: 'Company',
      newsletter: 'Newsletter',
      profile: 'Profile',
      status: 'Status',
      comments: 'Comments'
    },
    companies: {
      companyProfile: 'Company Profile',
      buttons: {
        activeCompany: 'active Company',
        deleteCompany: 'Deleted',
        editTooltip: 'EDIT',
        deleteTooltip: 'DELETE',
        restoreTooltip: 'Restore'
      },
      tableHeaders: {
        name: 'Name',
        date: 'Date',
        location: 'Location',
        phone: 'Phone',
        email: 'Email',
        planTier: 'Premium status',
        lastChanges: 'Last Changes',
        action: 'Action'
      },
      totalCompany: 'Total Companies:',
      companiesRender: 'Companies',
      createDate: 'Creation date:',
      lastEdit: 'Last edited:',
      from: 'from'
    },
    comments: {
      navigationButtons: {
        all: 'All',
        comments: 'Comments',
        responses: 'Responses',
        rating: 'Rating'
      },
      controlButtons: {
        posted: 'Post',
        edit: 'Edit',
        delete: 'Delete'
      },
      modals: {
        title: 'Are you sure?',
        cancelBtn: 'Cancel',
        postBtn: 'Post',
      },
      commentsName: 'Comment',
      ratingsName: 'Rating',
      commentsResponse: 'Response to a comment',
      totalComments: 'Total',
      saveBtn: 'Save',
      commentStates: {
        deleted: 'Deleted',
        updated: 'Updated',
        posted: 'Posted'
      }
    },
    profile: {
      pageTitle: 'My Profile',
      saveBtn: 'Save',
      changePassBtn: 'Change password',
      passwordCapture: 'Password',
    },
    statuses: {
      buttons: {
        activeStatues: 'All Companies',
        verifiedStatuses: 'Verified',
        notVerifiedStatuses: 'Not Verified'
      },
      tableHeaders: {
        name: 'Name',
        verification: 'Verification'
      }
    },
    modals: {
      companyDeleteConfirmation: {
        text: 'This company profile will be permanently deleted. The procedure cannot be revoked.',
        cancelBtn: 'Cancel',
        approveBtn: 'Delete'
      },
      postCommentConfirmation: {
        text: 'Are you sure?',
        approveBtn: 'Post',
        cancelBtn: 'Cancel'
      },
      deleteCommentConfirmation: {
        text: 'This comment will be permanently deleted. The process cannot be revoked.',
        approveBtn: 'Clear',
        cancelBtn: 'Cancel'
      },
      deleteRatingConfirmation: {
        text: 'This rating will be permanently deleted. The process cannot be revoked.',
        approveBtn: 'Clear',
        cancelBtn: 'Cancel'
      },
      deletePictureCompany: {
        text: 'Are you sure?',
        approveBtn: 'Clear',
        cancelBtn: 'Cancel'
      },
      saveChangesCompanyProfile: {
        text: 'Would you like to save the changes?',
        approveBtn: 'Yes',
        cancelBtn: 'No'
      },
    }
  }
}
