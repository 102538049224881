import { uniq } from 'ramda';

import {
  CLEAR_COMPANY_LIST,
  FETCH_COMPANIES_ADD_SUCCESS,
  FETCH_COMPANIES_BY_ADMIN,
  FETCH_COMPANIES_ERROR,
  FETCH_COMPANIES_INIT_SUCCESS,
  FETCH_COMPANIES_REQUEST,
  FETCH_COMPANY_ITEM_ERROR,
  FETCH_COMPANY_ITEM_REQUEST,
  FETCH_INITIAL_COMPANY_ITEM_REQUEST,
  FETCH_COMPANY_ITEM_SUCCESS,
  FETCH_INITIAL_COMPANY_ITEM_SUCCESS,
  LAST_CREATES_COMPANY_ERROR,
  LAST_CREATES_COMPANY_REQUEST,
  LAST_CREATES_COMPANY_SUCCESS,
  REMOVE_COMPANY_LOGO_ERROR,
  REMOVE_COMPANY_LOGO_REQUEST,
  REMOVE_COMPANY_LOGO_SUCCESS,
  UPDATE_COMPANY_ERROR,
  UPDATE_COMPANY_LOGO_ERROR,
  UPDATE_COMPANY_LOGO_REQUEST,
  UPDATE_COMPANY_LOGO_SUCCESS,
  UPDATE_COMPANY_REQUEST,
  UPDATE_COMPANY_SUCCESS,
  COMPANY_STATUS_ACTIVE_SUCCESS,
  COMPANY_STATUS_INACTIVE_SUCCESS,
  UPDATE_COMPANY_STATUS_ERROR,
  DELETE_COMPANY_ERROR,
  DELETE_COMPANY_SUCCESS,

  RESET_PASS_COMPANY_REQUEST,
  RESET_PASS_COMPANY_SUCCESS,
  RESET_PASS_COMPANY_ERROR,

  UPDATE_VERIFIED_COMPANY_ERROR,
  UPDATE_VERIFIED_COMPANY_REQUEST,
  UPDATE_VERIFIED_COMPANY_SUCCESS,
  UPLOAD_PICTURE_COMPANY_REQUEST,
  UPLOAD_PICTURE_COMPANY_SUCCESS,
  UPLOAD_PICTURE_COMPANY_ERROR,
  REMOVE_PICTURE_COMPANY_REQUEST,
  REMOVE_PICTURE_COMPANY_SUCCESS,
  REMOVE_PICTURE_COMPANY_ERROR,
  UPDATE_EMPLOYEES_REQUEST,
  SAVE_COMPANY_EDIT_DRAFT,
  CLEAR_COMPANY_EDIT_DRAFT,
  TOGGLE_SUCCESS_ALERT,

  UPDATE_PLAN_TIER_REQUEST,
  UPDATE_PLAN_TIER_SUCCESS,
  UPDATE_PLAN_TIER_ERROR
} from './companyConstants';

const initialState = {
  list: [],
  amount: 0,
  loading: false,
  error: null,

  companyItem: {},
  isUnsavedChanges: false, // indicator which shows if user save changes or no.
  defaultPlaceholder: {
    name: "example",
    email: "example@mail.com"
  },
  lastCreated: {
    list: [],
    fullAmount: 0,
    loading: false,
    error: null,
  },
  
  isAlertOpen: false
};

export default function reducer(state = initialState, action) {
  const { type, error, payload } = action;

  switch (type) {
    case LAST_CREATES_COMPANY_REQUEST: {
      return {
        ...state,
        lastCreated: {
          ...state.lastCreated,
          loading: true,
        }
      }
    }
    case FETCH_COMPANIES_REQUEST:
    case FETCH_COMPANIES_BY_ADMIN:
    case FETCH_COMPANY_ITEM_REQUEST:
    case FETCH_INITIAL_COMPANY_ITEM_REQUEST:
    case UPDATE_COMPANY_REQUEST:
    case UPDATE_COMPANY_LOGO_REQUEST:
    case REMOVE_COMPANY_LOGO_REQUEST:
    case UPDATE_VERIFIED_COMPANY_REQUEST:
    case UPLOAD_PICTURE_COMPANY_REQUEST:
    case REMOVE_PICTURE_COMPANY_REQUEST:
    case UPDATE_EMPLOYEES_REQUEST:
    case UPDATE_PLAN_TIER_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }
    case LAST_CREATES_COMPANY_SUCCESS: {
      return {
        ...state,
        lastCreated: {
          ...state.lastCreated,
          loading: false,
          fullAmount: payload.amount,
          list: uniq([...state.lastCreated.list, ...payload.entities]),
        }
      }
    }
    case FETCH_COMPANIES_INIT_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: [...payload.entities],
        amount: payload.count,
        isAllEntitiesFromKanton: payload.isAllEntitiesFromKanton
      }
    }
    case FETCH_COMPANIES_ADD_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: [ ...state.list, ...payload.entities],
        amount: payload.count,
        isAllEntitiesFromKanton: payload.isAllEntitiesFromKanton
      }
    }
    case FETCH_COMPANY_ITEM_SUCCESS: {
      return {
        ...state,
        loading: false,
        companyItem: payload,
      }
    }
    case FETCH_INITIAL_COMPANY_ITEM_SUCCESS: {
      return {
        ...state,
        loading: false,
        companyItem: 
          {...payload, 
            name: state.defaultPlaceholder.name, 
            base: {...payload.base, email: state.defaultPlaceholder.email}, isInitial: true
          },
      }
    }
    case UPDATE_COMPANY_SUCCESS: {
      return {
        ...state,
        isUnsavedChanges: false,
        companyItem: payload,
        loading: false,
      }
    }
    case UPDATE_VERIFIED_COMPANY_SUCCESS: {
      const { _id, verified } = payload;
      return {
        ...state,
        loading: false,
        list: state.list.map(el => el._id === _id ? { ...el, verified } : el)
      }
    }
    case UPDATE_COMPANY_LOGO_SUCCESS: {
      return {
        ...state,
        companyItem: {
          ...state.companyItem,
          logo: payload.logo
        },
        loading: false,
      }
    }

    case REMOVE_COMPANY_LOGO_SUCCESS: {
      return {
        ...state,
        companyItem: {
          ...state.companyItem,
          logo: null
        },
        loading: false,
      }
    }
    case CLEAR_COMPANY_LIST: {
      return {
        ...state,
        list: [],
        amount: 0,
      }
    }
    case UPLOAD_PICTURE_COMPANY_SUCCESS: {
      return {
        ...state,
        loading: false,
        companyItem: {
          ...state.companyItem,
          pictures: payload,
        }
      }
    }
    case REMOVE_PICTURE_COMPANY_SUCCESS: {
      return {
        ...state,
        companyItem: {
          ...state.companyItem,
          pictures: state.companyItem.pictures.filter(p => p !== payload)
        },
        loading: false,
      }
    }

    case COMPANY_STATUS_INACTIVE_SUCCESS:
    case DELETE_COMPANY_SUCCESS:
    case COMPANY_STATUS_ACTIVE_SUCCESS: {
      return {
        ...state,
        list: state.list.filter(company => company._id !== payload.companyId),
        amount: state.amount - 1
      }
    }

    case RESET_PASS_COMPANY_REQUEST:
    case RESET_PASS_COMPANY_SUCCESS:
        return {
          ...state,
          error: null
        }
      case RESET_PASS_COMPANY_ERROR: {
        return {
          ...state,
          error: action.error
        }
      }

    case LAST_CREATES_COMPANY_ERROR: {
      return {
        ...state,
        lastCreated: {
          ...state.lastCreated,
          error: error,
        }
      }
    }
    case SAVE_COMPANY_EDIT_DRAFT: {
      return {
        ...state,
        isUnsavedChanges: true
      }
    }
    case CLEAR_COMPANY_EDIT_DRAFT: {
      return {
        ...state,
        isUnsavedChanges: false
      }
    }
    case TOGGLE_SUCCESS_ALERT: {
      return {
        ...state,
        isAlertOpen: payload
      }
    }
    case UPDATE_PLAN_TIER_SUCCESS: {
      return {
        ...state,
        loading: false,
      }
    }
    case FETCH_COMPANIES_ERROR:
    case FETCH_COMPANY_ITEM_ERROR:
    case UPDATE_COMPANY_LOGO_ERROR:
    case UPDATE_COMPANY_ERROR:
    case DELETE_COMPANY_ERROR:
    case UPDATE_COMPANY_STATUS_ERROR:
    case REMOVE_COMPANY_LOGO_ERROR:
    case UPDATE_VERIFIED_COMPANY_ERROR:
    case UPLOAD_PICTURE_COMPANY_ERROR:
    case REMOVE_PICTURE_COMPANY_ERROR:
    case UPDATE_PLAN_TIER_ERROR: {
      return {
        ...state,
       error: error,
       loading: false
      }
    }

    default:
      return state;
  }

}
