import * as R from 'ramda';
import styled from 'styled-components';

import {getThemeColor, getThemeSize} from '../../../../utils/theme';
import { CheckBox } from '../../common/CheckBox';
import { Text } from '../../common/Text';

export const TableWrapS = styled.div`
  //overflow-y: hidden; // TODO: flexible table
  width: 100%;
  max-width: ${R.propOr('100%', 'maxWidth')};
  -webkit-overflow-scrolling: touch;
  overflow-x : auto;
`;

export const TableS = styled.table`  
  width: 100%;
  background-color: ${getThemeColor(['white'])};
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
  padding: 0 15px;
  > tbody > tr > td  {
    padding: 15px 10px;
  }
`;

export const TableHeadS = styled.thead`
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
`;

export const TableHeadTHS = styled.th`
  color: ${getThemeColor(['violet'])};
  font-size: ${getThemeSize(['bigText'])};
  font-weight: normal;
  text-transform:uppercase;
  text-align: left;
  padding: 15px 10px;
  white-space: nowrap;
  
  span {
    vertical-align: middle;
  }
`;

export const TableTitleNameS = styled.span`
  display: block;
  font-weight: bold;
  color: ${getThemeColor(['primary'])};
  text-align: left;
  text-transform: uppercase;
  max-width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const TableTextS = styled(TableTitleNameS)`
  color: #717171;
  font-weight: normal;
`;

export const TableTextSmallS = styled(TableTextS)`
  text-transform: capitalize;
`;

export const TableTextLowercaseS = styled(TableTextS)`
  text-transform: lowercase;
`;

export const TableTextDateS = styled.p`
  color: #717171;
  font-weight: normal;
`;

export const TableTextByS = styled.span`
  display:block;
  color: #717171;
  font-weight: normal;
  margin-top: 5px;
  text-transform: initial;
`;

export const TableIconsWrapS = styled(TableTitleNameS)`
  display:flex;
  align-items: center;
`;

export const TableRow = styled.tr`
  border-bottom: 1px solid ${getThemeColor(['grayBlue'])};
`;

export const PlanTierButtonsS = styled.span`
  display: flex;
  justify-content: space-around;
  color: ${getThemeColor(['primary'])};
  text-align: left;
  white-space: nowrap;
`;

export const CheckBoxS= styled(CheckBox)`

  :last-child {
    margin: 0 3px;
  }
`;

export const TextPlanTierS= styled(Text)`
  text-transform: capitalize;
`;
