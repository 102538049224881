import i18n from 'i18next';
import { minBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { companyFilterActions } from '../../../../modules/companyFilter';
import { DisplayFlexS } from '../../../../utils/stylesHelpers';
import { Text } from '../index';

import { HistogramButtons } from './HistogramButtons';
import { HistogramComponent } from './HistogramComponent';
import RangeInputs from './RangeInputs';
import { RangeSlider } from './RangeSlider';

const { setFilter: setReduxFilter } = companyFilterActions;

const HistogramWrapper = ({ dataItem, homeFilter, titleColor, label, filterName, setFilter, selectedRange }) => {
  const dispatch = useDispatch();
  const [range, setRange] = useState([0, dataItem.frequency.length]);
  const [inputData, setInputData] = useState([dataItem.min, dataItem.max]);

  const frequencyData = dataItem.frequency;
  const defaultRange = [0, frequencyData.length];
  const rangeData = dataItem.range;
  const priceData = [...rangeData];
  const calculateAverage = Math.floor(rangeData.reduce((a, b) => a + b, 0) / rangeData.length);

  const updateValue = (value) => {
    setInputData([
      priceData[value[0]],
      priceData[value[1]] === undefined ? priceData[priceData.length - 1] : priceData[value[1]],
    ]);
    setRange(value);
  };

  const clearData = () => {
    setRange(defaultRange);
    setInputData([priceData[0], priceData[priceData.length - 1]]);
  };

  const setRangeFromInput = (value) => {
    const updatedRange = [...defaultRange];

    const minIndex = priceData.findIndex((price) => Number(price) > value[0]);
    const maxIndex = priceData.findIndex((price) => Number(price) > value[1]);

    if (minIndex !== -1) {
      updatedRange[0] = minIndex - 1;
    }

    updatedRange[1] = maxIndex === -1 ? priceData.length : maxIndex - 1;

    setRange(updatedRange);
  };

  const handleSetFilter = () => {
    const filterData = {
      filterName: label,
      value: {
        [filterName]: inputData,
      },
    };

    if (setFilter) {
      setFilter(filterData);
    } else {
      dispatch(setReduxFilter(filterData));
    }
  };

  useEffect(() => {
    if (!selectedRange || !selectedRange.length) {
      setRange(defaultRange);
      setInputData([priceData[0], priceData[priceData.length - 1]]);
      return;
    }

    const foundRange = selectedRange.map((selectedVal) => {
      const exactIndex = dataItem.range.indexOf(selectedVal);
      if (exactIndex !== -1) {
        return exactIndex;
      }
      const closestValue = minBy(dataItem.range, (rangeVal) => Math.abs(rangeVal - selectedVal));
      return dataItem.range.indexOf(closestValue);
    });

    setRange(foundRange);
    setInputData(foundRange.map((idx) => dataItem.range[idx]));
  }, [dataItem]);

  return (
    <>
      <DisplayFlexS direction={homeFilter ? 'row' : 'column'} directionScreenMedium="column">
        <Text size="smallText" margin={homeFilter ? '0 5px 0 0' : '0'} color={titleColor}>
          {i18n.t([`companies.filter.histogramTitle`])}
        </Text>
        <Text size="smallText" color={titleColor}>
          {i18n.t('companies.filter.histogramText', { value: `${calculateAverage} CHF` })}
        </Text>
      </DisplayFlexS>

      <HistogramComponent dataValues={frequencyData} highlight={range} />

      <RangeSlider values={range} domain={defaultRange} mode={2} step={1} onUpdate={updateValue} />

      <RangeInputs
        homeFilter={homeFilter}
        label={label}
        filterName={filterName}
        setFilter={handleSetFilter}
        inputRange={inputData}
        setRangeFromInput={setRangeFromInput}
        setInputData={setInputData}
        currency="CHF"
        names={['min', 'max']}
      />

      <HistogramButtons
        label={label}
        filterName={filterName}
        inputData={inputData}
        onClear={clearData}
        setFilter={handleSetFilter}
      />
    </>
  );
};

HistogramWrapper.propTypes = {
  dataItem: PropTypes.object.isRequired,
  homeFilter: PropTypes.bool.isRequired,
  titleColor: PropTypes.string.isRequired,
  setFilter: PropTypes.func,
  selectedRange: PropTypes.array.isRequired,
  label: PropTypes.string,
  filterName: PropTypes.string,
};

export default HistogramWrapper;
