import { delay } from 'redux-saga';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { t } from "i18next";
import { push } from 'connected-react-router';

import { authModalLayouts } from '../../app/constants/modals';
import { get, post, remove, urls } from '../../utils/api';
import { routes } from "../../app/routes/routesConfig";
import { removeItem, setItem } from '../../utils/helpers/localStorage';
import { showErrorNotification, showSuccessNotification, toggleModal } from "../app/appActions";
import { login, logout } from "./authActions";
import { resetProfileData } from "../profile/profileAction";
import { eventGA } from '../../utils/GA';
import {
  DELETE_AVATAR_ACTIVE_USER_REQUEST,
  GET_ACTIVE_USER_ERROR,
  GET_ACTIVE_USER_REQUEST,
  GET_ACTIVE_USER_SUCCESS,
  LOGIN_ERROR,
  LOGIN_REQUEST,
  LOGIN_SUCCESS, LOGOUT_ADMIN_REQUEST,
  LOGOUT_ADMIN_SUCCESS,
  LOGOUT_ERROR,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,

  GENERATE_PASS_REQUEST,
  GENERATE_PASS_ERROR,

  RESET_PASS_ERROR,
  RESET_PASS_REQUEST,
  RESET_PASS_SUCCESS,
  RESOLVE_REGISTRATION_CONFLICT_ERROR,
  RESOLVE_REGISTRATION_CONFLICT_REQUEST,
  RESOLVE_REGISTRATION_CONFLICT_SUCCESS,
  SIGN_UP_ERROR,
  SIGN_UP_REQUEST,
  SIGN_UP_SUCCESS,
  UPDATE_ACTIVE_USER_ERROR,
  UPDATE_ACTIVE_USER_REQUEST,
  UPDATE_ACTIVE_USER_SUCCESS,
  UPDATE_AVATAR_ACTIVE_USER_ERROR,
  UPDATE_AVATAR_ACTIVE_USER_REQUEST,
  UPDATE_AVATAR_ACTIVE_USER_SUCCESS,
  GENERATE_PASS_SUCCESS,
} from './authConstants';
import { isAdmin } from '../../utils/helpers/applicationHelpers';
import { getCompanyEditRoute, getCompanyProfileAdminRoute } from '../../utils/helpers/routesBuilder';

export const signUpSaga = function* ({ payload, withNotification }) {
  try {
    const url = payload.isInitialCompany ? urls.adminCompanyRegistration : urls.registration

    const { data } = yield call(post, url, payload);

    yield put({
      type: SIGN_UP_SUCCESS,
    });

    if (withNotification) yield put(showSuccessNotification({text: t('modals.auth.registration.notificationBar.success')}));

    if (payload.isInitialCompany) {
      yield put(push(getCompanyProfileAdminRoute(data._id, data.name)));
    }

    else {
      yield put(login(payload));
      yield put(push(getCompanyEditRoute(data._id, data.name)));
    }


  } catch (err) {
    if (err.status === 409) { // conflict
      yield put(toggleModal({ layout: authModalLayouts.resolveRegistrationConflict }));
    }

    yield put({
      type: SIGN_UP_ERROR,
      error: err.data
    });

    if (withNotification) yield put(showErrorNotification({text: err.data.error.text}));
  }
};

export const loginSaga = function* ({ payload, isAdmin }) { // TODO: think about refactoring to have two sagas for admin and for client
  try {
    const { data } = yield call(post, urls.login, payload);

    if (isAdmin) {
      if (data.isAdmin) { // case when user make login from admin
        setItem('adminToken', data.accessToken);
        yield put(push('/admin'));
      } else {
        return yield put({
          type: LOGIN_ERROR,
          error: {
            text: 'User is not admin',
            messageCode: 1000
          }
        })
      }
    } else { // handle case if user try login on client side
      if (data.isAdmin) {
        return yield put({
          type: LOGIN_ERROR,
          error: {
            text: 'Admin can`t login in client side.',
            messageCode: 57
          }
        })
      }
      setItem( 'token', data.accessToken);
    }

    yield put({
      type: LOGIN_SUCCESS,
      payload: data
    });

    yield put({ type: GET_ACTIVE_USER_REQUEST });
    yield put(toggleModal({ open: false, layout: null }));
  }
  catch ({ data }) {
    yield put({
      type: LOGIN_ERROR,
      error: data.error[0] ? data.error[0] : data.error
    })
  }
};

export const logoutSaga = function* ({ payload}) {
  try {
    removeItem('token');

    yield put({
      type: LOGOUT_SUCCESS
    });

    // clear data which is loaded for logged in user.
    yield put(resetProfileData());

    if (payload.pathname === routes.PROFILE || payload.pathname.split('/').reverse()[0] === 'edit') {
      yield put(push(routes.HOME));
    }

    yield delay(100); // need this delay to open modal window.

    yield put(toggleModal({ open: true, layout: authModalLayouts.login }));
  }
  catch (e) {
    yield put({
      type: LOGOUT_ERROR,
      error: e.error
    })
  }

};

export const logoutAdminSaga = function* () {
  try {
    removeItem('adminToken');

    yield put({
      type: LOGOUT_ADMIN_SUCCESS
    });

    // clear data which is loaded for logged in user.
    yield put(resetProfileData());
  }
  catch (e) {
    yield put({
      type: LOGOUT_ERROR,
      error: e.error
    })
  }

};

export const resetPass = function* ({ payload }) {
  try {
    yield call(post, urls.resetPass, { ...payload, isAdmin: isAdmin() }); // { email }

    if (!isAdmin()) {
      yield put(toggleModal({ layout: authModalLayouts.login })); // navigate in modal
    }

    yield put({
      type: RESET_PASS_SUCCESS
    });
  } catch ({ data }) {
    yield put({
      type: RESET_PASS_ERROR,
      error: data.error,
    })
  }
};

export const generatePassSaga = function* () {
  try {
    const { data } = yield call(get, urls.generatePass);

    if (data.generatedPass) {
      yield put({
        type: GENERATE_PASS_SUCCESS,
        payload: data.generatedPass
      });
    }
  }
  catch(e) {
    yield put({
      type: GENERATE_PASS_ERROR,
      error: e.data
    });
  }
}

export const resolveRegistrationConflict = function* ({ payload }) {
  try {
    yield call(post, urls.resolveConflictByEmail, payload); // { email }

    yield put(toggleModal({ open: false, layout: authModalLayouts.null })); // close modal

    yield put({
      type: RESOLVE_REGISTRATION_CONFLICT_SUCCESS
    });
  } catch ({ data }) {
    yield put({
      type: RESOLVE_REGISTRATION_CONFLICT_ERROR,
      error: data.error,
    })
  }
};

const getActiveUser = function* () {
  try {
    const { data } = yield call(get, urls.activeUser);

    yield put({
      type: GET_ACTIVE_USER_SUCCESS,
      payload: data,
    });

    if (data.verified){
      yield eventGA({
        category: 'Auth',
        action: 'Login',
        label: 'Verified company'
      })
    }

  } catch ({ data }) {
    yield put({
      type: GET_ACTIVE_USER_ERROR,
      error: data.error,
    });

    const routerState = yield select((state) => state.router);
    yield put(logout(routerState));
  }
};

const updateActiveUserSaga = function* ({ payload }) {
  try {
    const { data } = yield call(post, urls.updateUser, payload);

    yield put({
      type: UPDATE_ACTIVE_USER_SUCCESS,
      payload: data,
    });
    yield put(showSuccessNotification({text: t('profile.notificationBar.successUpdateUser')}));
  } catch (e) {
    yield put({
      type: UPDATE_ACTIVE_USER_ERROR,
      error: e.data,
    });
    yield put(showErrorNotification({text: t('profile.notificationBar.errorUpdateUser')}));
  }
};

const updateActiveUserAvatarSaga = function* ({ payload }) {
  try {
    const { data } = yield call(post, urls.userChangeAvatar, payload);

    yield put({
      type: UPDATE_AVATAR_ACTIVE_USER_SUCCESS,
      payload: data.logo,
    });

  } catch (e) {
    yield put({
      type: UPDATE_AVATAR_ACTIVE_USER_ERROR,
      error: e.data,
    })
  }
};

const deleteActiveUserAvatarSaga = function* () {
  try {

    const { data } = yield call(remove, urls.userDeleteAvatar);

    yield put({
      type: UPDATE_AVATAR_ACTIVE_USER_SUCCESS,
      payload: data.logo,
    });
  } catch (e) {
    yield put({
      type: UPDATE_AVATAR_ACTIVE_USER_ERROR,
      error: e.data
    })
  }
};

export default function* authSaga() {
  yield all([
    takeEvery(SIGN_UP_REQUEST, signUpSaga),
    takeEvery(LOGIN_REQUEST, loginSaga),
    takeEvery(LOGOUT_REQUEST, logoutSaga),
    takeEvery(LOGOUT_ADMIN_REQUEST, logoutAdminSaga),
    takeEvery(GENERATE_PASS_REQUEST, generatePassSaga),
    takeEvery(RESET_PASS_REQUEST, resetPass),
    takeEvery(RESOLVE_REGISTRATION_CONFLICT_REQUEST, resolveRegistrationConflict),
    takeEvery(GET_ACTIVE_USER_REQUEST, getActiveUser),
    takeEvery(UPDATE_ACTIVE_USER_REQUEST, updateActiveUserSaga),
    takeEvery(UPDATE_AVATAR_ACTIVE_USER_REQUEST, updateActiveUserAvatarSaga),
    takeEvery(DELETE_AVATAR_ACTIVE_USER_REQUEST, deleteActiveUserAvatarSaga)
  ]);
}
