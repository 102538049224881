import { replace } from 'connected-react-router';
import { t } from 'i18next';
import * as R from 'ramda';
import { equals, filter, identity, isEmpty, omit, difference } from 'ramda';
import { matchPath } from 'react-router-dom';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';

import { COMPANY_STATE } from "../../app/components/adminPanel/companies/companiesComponent";
import { companySorts } from '../../app/constants/sorts';
import { routes } from '../../app/routes/routesConfig';
import {get, patch, post, remove, urls} from '../../utils/api';
import buildUrlWithQueries from '../../utils/helpers/buildUrlWithQuery';
import {showErrorNotification, showSuccessNotification} from '../app/appActions';
import {UPDATE_ACTIVE_USER_COMPANY_SUCCESS, UPDATE_AVATAR_ACTIVE_USER_SUCCESS} from "../auth/authConstants";
import { MODAL_TYPES } from "../modals/modalsConstants";

import { 
  updateCompany as updateCompanyAction,
  toggleSuccessModal,
  fetchCompaniesByAdmin,
  fetchDeletedCompanies
} from './companyActions';
import {
  ADD_COMPANY_VISIT,
  FETCH_COMPANIES_ADD_SUCCESS,
  FETCH_COMPANIES_BY_ADMIN,
  FETCH_DELETED_COMPANIES_REQUEST,
  FETCH_COMPANIES_ERROR,
  FETCH_COMPANIES_INIT_SUCCESS,
  FETCH_COMPANIES_REQUEST,
  FETCH_COMPANY_ITEM_ERROR,
  FETCH_COMPANY_ITEM_REQUEST,
  FETCH_INITIAL_COMPANY_ITEM_REQUEST,
  FETCH_INITIAL_COMPANY_ITEM_SUCCESS,
  FETCH_INITIAL_COMPANY_ITEM_ERROR,

  FETCH_COMPANY_ITEM_SUCCESS,
  LAST_CREATES_COMPANY_ERROR,
  LAST_CREATES_COMPANY_REQUEST,
  LAST_CREATES_COMPANY_SUCCESS,
  REMOVE_COMPANY_LOGO_ERROR,
  REMOVE_COMPANY_LOGO_REQUEST,
  REMOVE_COMPANY_LOGO_SUCCESS,
  UPDATE_COMPANY_ERROR,
  UPDATE_COMPANY_LOGO_ERROR,
  UPDATE_COMPANY_LOGO_REQUEST,
  UPDATE_COMPANY_LOGO_SUCCESS,
  UPDATE_COMPANY_REQUEST,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_STATUS_REQUEST,
  UPDATE_COMPANY_STATUS_ERROR,
  COMPANY_STATUS_INACTIVE_SUCCESS,
  COMPANY_STATUS_ACTIVE_SUCCESS,
  DELETE_COMPANY_REQUEST,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_ERROR,

  RESET_PASS_COMPANY_REQUEST,
  RESET_PASS_COMPANY_SUCCESS,
  RESET_PASS_COMPANY_ERROR,

  UPDATE_VERIFIED_COMPANY_REQUEST,
  UPDATE_VERIFIED_COMPANY_ERROR,
  UPDATE_VERIFIED_COMPANY_SUCCESS,
  UPLOAD_PICTURE_COMPANY_REQUEST,
  REMOVE_PICTURE_COMPANY_REQUEST,
  REMOVE_PICTURE_COMPANY_SUCCESS,
  UPLOAD_PICTURE_COMPANY_SUCCESS,
  UPLOAD_PICTURE_COMPANY_ERROR,
  REMOVE_PICTURE_COMPANY_ERROR,
  UPDATE_EMPLOYEES_REQUEST,
  CREATE_MESSAGE_TO_COMPANY,

  UPDATE_PLAN_TIER_REQUEST,
  UPDATE_PLAN_TIER_SUCCESS,
  UPDATE_PLAN_TIER_ERROR
} from './companyConstants';

export const fetchLastCreatedSaga = function* ({payload}) {
    try {
        const {data} = yield call(get, buildUrlWithQueries(urls.lastCreatedCompanies, payload));

        yield put({
            type: LAST_CREATES_COMPANY_SUCCESS,
            payload: data
        });

    } catch (err) {
        yield put({
            type: LAST_CREATES_COMPANY_ERROR,
            error: err.data.error
        });
    }
};

export const fetchCompaniesSaga = function* ({payload: { makeSearch}}) {
    try {
        // take filters and pagination data from redux store
        const sortSelector = state => state.companyFilter.sort;
        const filterSelector = state => state.companyFilter.filter;
        const searchSelector = state => state.companyFilter.search;
        const countPerPageSelector = state => state.companyFilter.countPerPage;
        const pageSelector = state => state.companyFilter.page;
        const isInfinityScrollSelector = state => state.companyFilter.isInfinityScroll;

        const sort = yield select(sortSelector);
        const filters = yield select(filterSelector);
        const search = yield select(searchSelector);
        const countPerPage = yield select(countPerPageSelector);
        const page = yield select(pageSelector);
        const isInfinityScroll = yield select(isInfinityScrollSelector);

        const isUserSearchRequest = makeSearch && !!search.length;

        const query = {
            skip: (page * countPerPage) - countPerPage,
            limit: countPerPage,
            sort,
            ...(isUserSearchRequest ? {} : filter((val) => !!val && !isEmpty(val), filters))
          };

        if (search) {
            query.search = search;
        }
        
        const {data} = yield call(post, urls.companiesList,
            equals(sort, companySorts.POPULAR.value)
            ? omit(['sort'], query)
            : identity(query)
        );

        yield put({
          // handle pagination
          type: isInfinityScroll === false || (page === 1) ? FETCH_COMPANIES_INIT_SUCCESS : FETCH_COMPANIES_ADD_SUCCESS,
          payload: data, // { count, entities, isAllEntitiesFromKanton }
        })
    } catch (err) {
        yield put({
            type: FETCH_COMPANIES_ERROR,
            error: err.data.error
        });
    }
};

export const fetchCompanyItemSaga = function* ({payload}) {
    try {
        const {data} = yield call(get, urls.companyItem(payload));

        yield put({
            type: FETCH_COMPANY_ITEM_SUCCESS,
            payload: data
        })

    } catch (err) {
        yield put({
            type: FETCH_COMPANY_ITEM_ERROR,
            error: err.data.error
        })
    }
};

export const fetchInitialCompanyItemSaga = function* () {
  try {
    const {data} = yield call(get, urls.companyInitial);

    yield put({
        type: FETCH_INITIAL_COMPANY_ITEM_SUCCESS,
        payload: data
    })

  } 
  catch (err) {
    yield put({
      type: FETCH_INITIAL_COMPANY_ITEM_ERROR,
      error: err.data
    })
  }
};

const updateCompany = function* ({ payload, isAdmin }) {
    try {
      const locationState = yield select(({ router }) => router.location);

      const { data } = yield call(patch, urls.updateCompany(payload._id), payload);

        yield put({
            type: UPDATE_COMPANY_SUCCESS,
            payload: data,
        });

        // update activeUser at authReducer in case user edit company from client
        // TODO: rewrite storing of active user in to two separate properties.
        if (!isAdmin) {
          yield put({
            type: UPDATE_ACTIVE_USER_COMPANY_SUCCESS,
            payload: data,
          });
        }

        const match = matchPath(locationState.pathname, routes.COMPANY_EDIT);
        if (match.params.companyName !== data.name) {
            yield put(replace( // update url in case name was changed.
                routes.COMPANY_EDIT
                    .replace(':id', match.params.id)
                    .replace(':companyName', data.name)
            ));
        }

        yield put(showSuccessNotification({text: t('companyEdit.notificationBar.success')}));

    } catch (err) {
        yield put({
            type: UPDATE_COMPANY_ERROR,
            error: err.data
        });
        yield put(showErrorNotification({text: t('companyEdit.notificationBar.error')}));
    }
};

export const updateCompanyLogoSaga = function* ({payload: {_id, formData}}) {
    try {
        const {data} = yield call(post, urls.updateCompanyLogo(_id), formData);

        yield put({
            type: UPDATE_COMPANY_LOGO_SUCCESS,
            payload: data,
        });

        // update activeUser.logo at authReducer
        yield put({
            type: UPDATE_AVATAR_ACTIVE_USER_SUCCESS,
            payload: data.logo,
        });
    } catch (e) {
        yield put({
            type: UPDATE_COMPANY_LOGO_ERROR,
            error: e.data
        })
    }
};

export const removeCompanyLogoSaga = function* ({ payload, isAdmin }) {
    try {
        yield call(remove, urls.deleteCompanyLogo(payload));
        //You make a call in order to get data without a logo

        const { data } = yield call(get, urls.companyItem(payload));

        yield put({
            type: REMOVE_COMPANY_LOGO_SUCCESS,
        });

        // remove activeUser.logo at authReducer
        if (!isAdmin) {
          yield put({
            type: UPDATE_ACTIVE_USER_COMPANY_SUCCESS,
            payload: data,
          });
        }
    } catch (e) {
        yield put({
            type: REMOVE_COMPANY_LOGO_ERROR,
            error: e.data
        })
    }
};

export const addCompanyVisit = function* ({payload}) {
    try {
        yield call(post, urls.addCompanyVisit(payload));
    } catch (err) {
      // eslint-disable-next-line
        console.log(err);
    }
};

const fetchCompaniesByAdminSaga = () => function* ({ payload: { page, itemsPerPage, sort, search, verified } }) {
    try {
      const query = {
          skip: page * itemsPerPage,
          limit: itemsPerPage,
      };

      if (sort && Object.keys(sort).length) {
        query.sort = Object.keys(sort)[0];
        query.sortDirection = Object.values(sort)[0]
      }
      if (search) {
        query.search = search;
      }

      if (verified) {
        query.verified = verified
      }

      const { data } = yield call(post, urls.companiesList, query);

      yield put({
          type: FETCH_COMPANIES_INIT_SUCCESS,
          payload: data, // { count, entities }
      })
    } catch (err) {
        yield put({
            type: FETCH_COMPANIES_ERROR,
            error: err.data.error
        });
    }
};

const updateCompanyStatusSaga = function* ({ payload: { status, companyId } }) {
  try {
    yield call(patch, urls.setCompanyStatus(companyId), { status });

    yield put({
      type: status === 'active' ? COMPANY_STATUS_ACTIVE_SUCCESS : COMPANY_STATUS_INACTIVE_SUCCESS,
      payload: { companyId },
    })
  } catch (err) {
    yield put({
      type: UPDATE_COMPANY_STATUS_ERROR,
      error: err.data.error
    });
  }
};

// eslint-disable-next-line no-unused-vars
const deleteCompanySaga = function* ({ payload: { companyId, status } }) {
  try {

    if (!companyId) {
      companyId = yield select(({ modals }) => modals[MODAL_TYPES.COMPANY_DELETE_CONFIRMATION].companyId);
    }

    yield call(remove, urls.companyItem(companyId));

    yield put({
      type: DELETE_COMPANY_SUCCESS,
      payload: { companyId },
    })
  } catch (err) {
    yield put({
      type: DELETE_COMPANY_ERROR,
      error: err.data.error
    });
  }
};

const resetPassCompanyAdminSaga = function* ({ payload }) {
  try {
    const {isSuccess} = yield call(post, urls.resetPassAdmin, { ...payload});

    if (isSuccess) {
      yield put({
        type: RESET_PASS_COMPANY_SUCCESS
      });
      yield put(showSuccessNotification({text: t('modals.resetPass.notificationBar.success')}));
    }
  }
  catch (e) {
    yield put({
      type: RESET_PASS_COMPANY_ERROR,
      error: e.data.error
    });
    yield put(showErrorNotification({text: t('modals.resetPass.notificationBar.error')}));
  }
}

const updateVerifiedCompanySaga = function* ({ payload: { companyId, verified } }) {
  try {
    const { data } = yield call(post, urls.updateVerifiedCompany(companyId), { verified });
    yield put({
      type: UPDATE_VERIFIED_COMPANY_SUCCESS,
      payload: data
    })
  } catch (err) {
    yield put({
      type: UPDATE_VERIFIED_COMPANY_ERROR,
      error: err.data.error
    });
  }
};

export const uploadCompanyPictureSaga = function* ({ payload: { companyId, pictureData }, resetImagesStates }) {
  try {
    let pics = [];
    for (let i = 0; i < pictureData.length; i++) { // Have to used for loop in order to use call effect.
      const { data } = yield call(post, urls.pictureCompany(companyId), pictureData[i]);

      if (i === pictureData.length - 1) {
        pics = data.pictures;
      }
    }

    yield put({
      type: UPLOAD_PICTURE_COMPANY_SUCCESS,
      payload: pics
    });

    resetImagesStates();

    yield put(showSuccessNotification({text: t('companyEdit.notificationBar.successUploadPicture')}));

  } catch (e) {
    yield put({
      type: UPLOAD_PICTURE_COMPANY_ERROR,
      error: e.data
    })

    yield put(showErrorNotification({text: t('companyEdit.notificationBar.error')}));
  }
};

export const removeCompanyPictureSaga = function* ({ payload: { id, pictureUrl } }) {
  try {
    yield call(remove, urls.pictureCompany(id), { pictureUrl });

    yield put({
      type: REMOVE_PICTURE_COMPANY_SUCCESS,
      payload: pictureUrl
    });

  } catch (e) {
    yield put({
      type: REMOVE_PICTURE_COMPANY_ERROR,
      error: e.data
    })

    yield put(showErrorNotification({text: t('companyEdit.notificationBar.error')}));
  }
};

const updateEmployees = function* ({ payload, isAdmin }) {
  try {
    const company = yield select(({ company }) => company.companyItem);

    for (let i = 0; i < payload.length; i++) {
      const existingEmployee = company.employees.find(({ _id }) => _id === payload[i]._id);

      if (existingEmployee) {
        if (typeof payload[i].avatar === 'object' && !!existingEmployee.avatar) {
          yield call(remove, urls.employeeAvatar(company._id), { pictureUrl: existingEmployee.avatar });
          const { data } = yield call(post, urls.employeeAvatar(company._id), payload[i].avatar.data);
          payload[i].avatar = data.url;
        } else if (typeof payload[i].avatar === 'object' && !existingEmployee.avatar) {
          const { data } = yield call(post, urls.employeeAvatar(company._id), payload[i].avatar.data);
          payload[i].avatar = data.url;
        } else if (!payload[i].avatar) { // user delete picture
          yield call(remove, urls.employeeAvatar(company._id), { pictureUrl: existingEmployee.avatar });
        }
        // in other case nothing have changed.
      // eslint-disable-next-line no-extra-boolean-cast
      } else if (!!payload[i].avatar) { // in case employee was added and no saved.
          const { data } = yield call(post, urls.employeeAvatar(company._id), payload[i].avatar.data);
          payload[i].avatar = data.url;
      }
    }

    if (payload.length < company.employees.length) { // dropped employee
      const deletedEmployees = difference(company.employees, payload);
      for (let i = 0; i < deletedEmployees.length; i++) {
        yield call(remove, urls.employeeAvatar(company._id), { pictureUrl: deletedEmployees[i].avatar });
      }
    }

    yield put(updateCompanyAction({ ...company, employees: payload }, isAdmin))
  } catch (e) {
    yield put({
      type: UPDATE_COMPANY_ERROR,
      error: e.data
    })
  }
}

const createMessageToCompanySaga = function* ({ payload }) {
  try {
    yield call(post, urls.contactCompany, payload);
    yield put(toggleSuccessModal(true));

  } catch (e) {
    yield put(showErrorNotification({text: t('modals.successModal.error')}));
  }
};

const updatePlanTierSaga = function* ({ payload: 
  { planTier, companyId, page, itemsPerPage, sort, companiesStateType }
}) {
  try {
    const planTierdata = {
      "entities": [
        {
          "id": companyId,
          "data": {
            "planTier": planTier
          }
        }
      ]
    };

    const {data} = yield call(post, urls.updateEntitiesById, planTierdata);

    yield put({
      type: UPDATE_PLAN_TIER_SUCCESS,
      payload: data
    });

    const getSortValue = (sort) => R.pickBy((val) => val !== 0, sort);

    // Fetch updated companies based on current filters
    if (companiesStateType === COMPANY_STATE.DELETED) {
      yield put(fetchDeletedCompanies(page, itemsPerPage, getSortValue(sort)));
    } else {
      yield put(fetchCompaniesByAdmin(page, itemsPerPage, getSortValue(sort)));
    }

  } catch (err) {
    yield put({
      type: UPDATE_PLAN_TIER_ERROR,
      error: err.data.error
    });
  }
};

export default function* companySaga() {
  yield all([
    takeEvery(LAST_CREATES_COMPANY_REQUEST, fetchLastCreatedSaga),
    takeEvery(FETCH_COMPANIES_REQUEST, fetchCompaniesSaga),
    takeEvery(FETCH_COMPANY_ITEM_REQUEST, fetchCompanyItemSaga),
    takeEvery(FETCH_INITIAL_COMPANY_ITEM_REQUEST, fetchInitialCompanyItemSaga),
    takeEvery(UPDATE_COMPANY_REQUEST, updateCompany),
    takeEvery(UPDATE_COMPANY_LOGO_REQUEST, updateCompanyLogoSaga),
    takeEvery(REMOVE_COMPANY_LOGO_REQUEST, removeCompanyLogoSaga),
    takeEvery(ADD_COMPANY_VISIT, addCompanyVisit),
    takeEvery(FETCH_COMPANIES_BY_ADMIN, fetchCompaniesByAdminSaga(urls.companiesList)),
    takeEvery(FETCH_DELETED_COMPANIES_REQUEST, fetchCompaniesByAdminSaga(urls.deletedCompanies)),
    takeEvery(UPDATE_COMPANY_STATUS_REQUEST, updateCompanyStatusSaga),
    takeEvery(DELETE_COMPANY_REQUEST, deleteCompanySaga),
    takeEvery(RESET_PASS_COMPANY_REQUEST, resetPassCompanyAdminSaga),
    takeEvery(UPDATE_VERIFIED_COMPANY_REQUEST, updateVerifiedCompanySaga),
    takeEvery(UPLOAD_PICTURE_COMPANY_REQUEST, uploadCompanyPictureSaga),
    takeEvery(REMOVE_PICTURE_COMPANY_REQUEST, removeCompanyPictureSaga),
    takeEvery(UPDATE_EMPLOYEES_REQUEST, updateEmployees),
    takeEvery(CREATE_MESSAGE_TO_COMPANY, createMessageToCompanySaga),
    takeEvery(UPDATE_PLAN_TIER_REQUEST, updatePlanTierSaga),
  ]);
}
