import { push } from 'connected-react-router';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { reviewActions } from '../../../modules/review';
import { hasAccessToAdminPanel, isAdminTokenPresent } from '../../../utils/auth';
import { DisplayFlexS } from '../../../utils/stylesHelpers';
import { routes } from '../../routes/routesConfig';
import { NotificationBar } from '../common';

import Header from './adminLayout/Header/HeaderComponent';
import Sidebar from './adminLayout/Sidebar/SidebarComponent';

/*
* This is the main wrapper where should be combined all layout for admin. It will be rendered for all views.
*
* */

export const AdminLayoutWrap = styled.div`
  display:flex;
  align-items:flex-start;
  justify-content:space-between;
  height: 1000%;
`;

export const LayoutWrap = styled.div`
    width: 100%;
    max-width: 1400px;
    padding: 80px 65px 165px;
    height: 100%;
`;

export const MainLayoutWrap = styled(DisplayFlexS)`
  flex: 0 0 81%;
  max-width: 81%;
`;

const AdminLayout = ({ children }) => {
  const dispatch = useDispatch();
  const activeUser = useSelector((state) => state.auth.activeUser);

  useEffect(() => {
    if (!isAdminTokenPresent()) {
      dispatch(push(`${routes.ADMIN.BASE}${routes.ADMIN.LOGIN}`));
      return;
    }
    dispatch(reviewActions.fetchUnhandledCommentsCounts());
  }, []);

  useEffect(() => {
    if (activeUser && !hasAccessToAdminPanel(activeUser)) {
      dispatch(push(`${routes.ADMIN.BASE}${routes.ADMIN.LOGIN}`));
    }
  }, [activeUser]);

  return (
    <AdminLayoutWrap justify="space-between">

    <Sidebar />

    <MainLayoutWrap justify="space-between" direction="column">
      <Header />
      <LayoutWrap>{ children }</LayoutWrap>
    </MainLayoutWrap>

    <NotificationBar />
  </AdminLayoutWrap>
  );
};

export default AdminLayout;