import React from 'react';
import { useDispatch } from 'react-redux';

import { updatePlanTier } from '../../../../modules/company/companyActions';
import { PlanTierButtonsS, CheckBoxS, TextPlanTierS } from '../../common/table/TableS';


const PLAN_TIERS = ['basic', 'premium', 'premium+'];

const PlanTierButtons = ({ planTier, companyId, page, itemsPerPage, sort, companiesStateType }) => {
  const dispatch = useDispatch();
  const currentPlanTier = planTier ? planTier : PLAN_TIERS[0]


  const handleChangePlanTier = (updatedPlanTier) => {
    const updatedPlanTierValue = updatedPlanTier !== PLAN_TIERS[0] ? updatedPlanTier.toLowerCase() : null;

    dispatch(updatePlanTier(updatedPlanTierValue, companyId, (page - 1), itemsPerPage, sort, companiesStateType));
  };

  return (
    <PlanTierButtonsS>
      {PLAN_TIERS.map((tier) => (
          <label key={tier}>
            <TextPlanTierS weight={currentPlanTier === tier ? 'bold' : ''}>{tier}</TextPlanTierS>
            <CheckBoxS
              checked={currentPlanTier === tier}
              onChange={() => handleChangePlanTier(tier)}
            />
          </label>
      ))}
    </PlanTierButtonsS>
  );
};

export default PlanTierButtons;