import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { getThemeColor } from '../../../../../utils/theme';

export const SidebarWrapS = styled.div`
  flex: 0 0 19%;
  max-width:19%;
  background-color: ${getThemeColor(['red'])};
  height: 100%;
`;

export const SidebarProfileS = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 25px;
  padding-left: 40px;
  padding-bottom: 55px;
  margin-bottom: 25px;
  box-sizing: border-box;
  border-left: 4px solid transparent;
  transition: all .3s ease-in-out;
  &:hover,
  &.active {
    text-decoration: none;
    background: ${getThemeColor(['greyRed'])};
    border-left: 4px solid ${getThemeColor(['white'])};
  }
`;

export const SidebarAvatarS = styled.div`
  width: 60px;
  height: 60px;
  border: 2px solid ${getThemeColor(['white'])};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
`;

export const SidebarListS = styled.ul``;

export const SidebarListItemS = styled.li``;

export const SidebarItemIconS = styled(FontAwesomeIcon)`
  color: ${getThemeColor(['white'])};
  transition: all .3s ease-in-out;
  ${({ open }) => open && `
        transform: rotate(180deg);}
  `} 
`;

export const SidebarSubListS = styled.ul`
  background: ${getThemeColor(['greyRed'])};
  list-style: none;
  display: flex;
  flex-direction: column;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  height: 0;
  ${({ isActive }) => isActive && `
      visibility: visible;
      opacity: 1;
      pointer-events: auto;
      height: auto;`
  }
`;

export const SidebarListLinkS = styled(NavLink)`
    display: flex;
    padding: 25px 0 25px 40px;
    border-left: 4px solid transparent;
    transition: all .3s ease-in-out;
    text-decoration: none;
    &:hover,
    &.active {
        background: ${getThemeColor(['greyRed'])};
        border-left: 4px solid ${getThemeColor(['white'])};
        text-decoration: none;
    }
`;

export const SidebarSubListLinkS = styled(NavLink)`
    transition: all .3s ease-in-out;
    padding: 15px 15px 15px 75px;
    text-decoration: none;
    &:hover,
    &.active {
        text-decoration: none;
            span {
                color: ${getThemeColor(['white'])};
            }
    }
`;

